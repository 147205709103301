import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Virtual, Keyboard, Navigation } from "swiper"
import cx from "clsx"

import { usePhotoCarousel, useGetAllPhotos } from "@app/features/photo-carousel/store/hooks"
import { restoreScroll, useDisableContextMenu, useRouteChangeStart } from "@app/utils"
import { useGallery, useGlobalState } from "@app/contexts"
import { getAnimationSpeed } from "@app/features/photo-carousel/utils"

import { PhotoSlide } from "@app/features/photo-carousel/components/photo-slide"
import { VideoSlide } from "@app/features/photo-carousel/components/video-slide"

import { ProductsSection } from "@app/features/products/components/products-section"
import { useI18n } from "next-localization"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./carousel-engine.module.scss"

SwiperCore.use([Navigation, Virtual, Keyboard])

const CarouselEngine = ({ isDownloadEnabled, shouldHideProducts = false }) => {
  const $allMediaFiles = useGetAllPhotos()
  const { $activePhoto, $setActivePhoto } = usePhotoCarousel()
  const { gallery } = useGallery()
  const { t } = useI18n()

  const {
    userAgent: { isMobile, isTablet },
  } = useGlobalState()

  const animationSpeed = getAnimationSpeed(isMobile, isTablet)

  const prevButtonRef = React.useRef()
  const nextButtonRef = React.useRef()

  const swiperRef = React.useRef()
  useDisableContextMenu(swiperRef, !isDownloadEnabled)

  const isShowProductSection = !shouldHideProducts && gallery.isPrintableProductsEnabled

  useRouteChangeStart(() => restoreScroll())

  return (
    $allMediaFiles && (
      <Swiper
        ref={swiperRef}
        spaceBetween={20}
        slidesPerView={1}
        initialSlide={$activePhoto?.index}
        cssMode={false}
        speed={animationSpeed}
        className={cx(!isDownloadEnabled && "image-context-menu-disabler")}
        keyboard={{ enabled: true, onlyInViewport: true }}
        breakpoints={{ 1025: { allowTouchMove: false } }}
        navigation={{
          prevEl: prevButtonRef?.current,
          nextEl: nextButtonRef?.current,
          disabledClass: styles["disabled-arrow"],
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevButtonRef.current
          swiper.params.navigation.nextEl = nextButtonRef.current
          swiper.navigation.update()
        }}
        onActiveIndexChange={(swiper) => $setActivePhoto(swiper.activeIndex)}
        updateOnWindowResize
        virtual
      >
        {$allMediaFiles.map((mediaFile, index) => {
          const isPhoto = mediaFile.type === "photo"
          const isVideo = mediaFile.type === "video"

          return (
            <SwiperSlide key={mediaFile.id} virtualIndex={index} className={styles["swiper-slide"]}>
              {isPhoto && <PhotoSlide file={mediaFile} />}
              {isVideo && <VideoSlide file={mediaFile} />}
            </SwiperSlide>
          )
        })}
        {isShowProductSection && (
          <SwiperSlide
            key="products"
            virtualIndex={$allMediaFiles.length}
            className={cx(styles["swiper-slide"], styles["products"])}
          >
            <ProductsSection
              title={t("productsModal.base.title")}
              description={t("productsModal.base.description")}
              onlyAvailable={false}
              position={"modal"}
            />
          </SwiperSlide>
        )}

        <button ref={prevButtonRef} className={cx(styles["prev"], QA_CLASS_NAMES.fullSize.prev)}></button>
        <button ref={nextButtonRef} className={cx(styles["next"], QA_CLASS_NAMES.fullSize.next)}></button>
      </Swiper>
    )
  )
}

export { CarouselEngine }

import React from "react"
import { TourProvider } from "@reactour/tour"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useOnboarding } from "@app/features/onboarding/store/hooks"

import { STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { Cross } from "@app/ui/icons"
import { Button } from "@app/ui/button"

import styles from "./onboarding-tour.module.scss"

function OnboardingTour({ isVisibleNavi = true, stepsClassName, localStorageKey, children, onClose = () => {} }) {
  // https://github.com/elrumordelaluz/reactour/

  const i18n = useI18n()
  const [isVisibleNaviTour, setIsVisibleNaviTour] = React.useState(isVisibleNavi)
  const stepsCount = stepsClassName.length

  const { $hideOnboardingTour } = useOnboarding()

  function onCloseTour() {
    window.localStorage.setItem(localStorageKey, JSON.stringify(false))
    $hideOnboardingTour()
    onClose()
  }

  function handleCloseTour({ setIsOpen }) {
    setIsOpen(false)
    onCloseTour()
  }

  function handleCloseTourBtn(setIsOpen) {
    setIsOpen(false)
    onCloseTour()
  }

  function handleClickMask() {
    return false
  }

  function hideNavigation() {
    setIsVisibleNaviTour(false)
  }

  function showNavigation() {
    setIsVisibleNaviTour(true)
  }

  // Swipe
  let touchX = null
  function getTouches(e) {
    return e.touches || e.originalEvent.touches
  }

  function handleTouchStart(e) {
    const firstTouch = getTouches(e)[0]
    touchX = firstTouch.clientX
  }

  function handleTouchMove(e, stepsCount, { currentStep, setCurrentStep }) {
    if (touchX === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchX - currentTouch

    if (diff > 5 && currentStep !== stepsCount - 1) {
      setCurrentStep((step) => step + 1)
    }

    if (diff < -5 && currentStep !== 0) {
      setCurrentStep((step) => step - 1)
    }

    touchX = null
  }
  //

  function Close({ onClick }) {
    return (
      isVisibleNaviTour && (
        <button onClick={onClick} style={{ position: "absolute", right: 20, top: 20 }}>
          <Cross width={16} height={16} stroke="var(--color-black)" />
        </button>
      )
    )
  }

  const isVisibleDots = stepsCount > 1

  // обьект с шагами и всякими методами для работы с подсказками (по другому не придумано)
  const stepsObj = {
    [STEPS_CLASS_NAME_TOUR.downloadAll.name]: {
      selector: STEPS_CLASS_NAME_TOUR.downloadAll.selector,
      content: ({ setIsOpen }) => (
        <div className={cx(styles["tour-content"], styles["without-navi"])}>
          <span className={styles["tour-text"]}>
            {i18n.t("mobileOnboarding.downloadPhotosAll")}
            <Button className={styles["close-tour"]} onClick={() => handleCloseTourBtn(setIsOpen)}>
              {i18n.t("mobileOnboarding.okButton")}
            </Button>
          </span>
        </div>
      ),
      action: () => hideNavigation(),
    },
    [STEPS_CLASS_NAME_TOUR.download.name]: {
      selector: STEPS_CLASS_NAME_TOUR.download.selector,
      content: ({ setIsOpen, currentStep, setCurrentStep }) => {
        return (
          <div
            className={cx(styles["tour-content"], { [styles["without-navi"]]: !isVisibleDots })}
            onTouchStart={(e) => {
              handleTouchStart(e)
            }}
            onTouchMove={(e) => {
              handleTouchMove(e, stepsCount, { currentStep, setCurrentStep })
            }}
          >
            <span className={styles["tour-text"]}>{i18n.t("mobileOnboarding.downloadPhoto")}</span>
            {!isVisibleDots && (
              <Button className={styles["close-tour"]} onClick={() => handleCloseTourBtn(setIsOpen)}>
                {i18n.t("mobileOnboarding.okButton")}
              </Button>
            )}
          </div>
        )
      },
      action: () => {
        if (isVisibleDots) {
          showNavigation()
        } else {
          hideNavigation()
        }
      },
    },
    [STEPS_CLASS_NAME_TOUR.share.name]: {
      selector: STEPS_CLASS_NAME_TOUR.share.selector,
      content: ({ setIsOpen, currentStep, setCurrentStep }) => (
        <div
          className={cx(styles["tour-content"], { [styles["without-navi"]]: !isVisibleDots })}
          onTouchStart={(e) => {
            handleTouchStart(e)
          }}
          onTouchMove={(e) => {
            handleTouchMove(e, stepsCount, { currentStep, setCurrentStep })
          }}
        >
          <span className={styles["tour-text"]}>{i18n.t("mobileOnboarding.share")}</span>
          {!isVisibleDots && (
            <Button className={styles["close-tour"]} onClick={() => handleCloseTourBtn(setIsOpen)}>
              {i18n.t("mobileOnboarding.okButton")}
            </Button>
          )}
        </div>
      ),
      action: () => {
        if (isVisibleDots) {
          showNavigation()
        } else {
          hideNavigation()
        }
      },
    },
    [STEPS_CLASS_NAME_TOUR.like.name]: {
      selector: STEPS_CLASS_NAME_TOUR.like.selector,
      content: ({ setIsOpen, currentStep, setCurrentStep }) => (
        <div
          className={cx(styles["tour-content"], styles["without-navi"])}
          onTouchStart={(e) => {
            handleTouchStart(e)
          }}
          onTouchMove={(e) => {
            handleTouchMove(e, stepsCount, { currentStep, setCurrentStep })
          }}
        >
          <span className={styles["tour-text"]}>
            {i18n.t("mobileOnboarding.favorites")}
            <Button className={styles["close-tour"]} onClick={() => handleCloseTourBtn(setIsOpen)}>
              {i18n.t("mobileOnboarding.okButton")}
            </Button>
          </span>
        </div>
      ),
      action: () => hideNavigation(),
    },
  }

  const stepsTour = [] // пустой массив для сбора шагов
  stepsClassName.map((item) => {
    stepsTour.push(stepsObj[item])
  })

  return (
    <TourProvider
      steps={stepsTour}
      className={styles["tour"]}
      components={{ Close }}
      onClickMask={handleCloseTour}
      onClickClose={handleCloseTour}
      disableInteraction
      highlightedMaskClassName={styles["highlighted-mask"]}
      styles={{
        popover: (base) => ({
          ...base,
          top: "auto",
          maxWidth: "100%",
          padding: "0px",
          backgroundColor: "var(--color-white-fon)",
          "--reactour-accent": "var(--color-black)",
        }),
        maskArea: (base) => ({ ...base, rx: 33 }),
        maskWrapper: (base) => ({ ...base, opacity: 0.45 }),
        highlightedArea: (base) => ({ ...base, display: "block", rx: 33, ry: 33, fill: "rgba(255, 255, 255, 0.35)" }),
        controls: (base) => ({
          ...base,
          justifyContent: "center",
          position: "absolute",
          bottom: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: isVisibleNaviTour ? 25 : 0,
        }),
        dot: (base, { current }) => {
          const dotBackgroundColor = current ? "var(--color-black)" : "var(--color-silver-lake)"
          return {
            ...base,
            display: isVisibleNaviTour && isVisibleDots ? "inline-block" : "none",
            background: dotBackgroundColor,
            width: 6,
            height: 6,
          }
        },
        badge: () => ({ display: "none" }),
        button: () => ({ display: "none" }),
      }}
    >
      {children}
    </TourProvider>
  )
}

export { OnboardingTour }

import cx from "clsx"
import * as React from "react"

import { Spinner } from "@app/ui/spinner"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"
import { SmoothTransition } from "@app/ui/animations"

import { useLinkImageInView } from "utils"
import { useImageLoading } from "@app/utils"
import { useGlobalState } from "@app/contexts"
import { useSaveImageNotify } from "@app/features/masonry/hooks/use-save-image-notify"

import styles from "./photo-slide.module.scss"

interface PhotoSlidePropsInterface {
  file: {
    [key: string]: any
  }
}

const PhotoSlide = ({ file }: PhotoSlidePropsInterface) => {
  const { isImageLoaded, onImageLoad } = useImageLoading()
  const { imageProxyHost, size, imgFormat } = useGlobalState()
  const { events: longTapEvents } = useSaveImageNotify()

  const photo_path = file.photos[0]
  const typeSize = "s" // max size
  const { srcImage, isReadyImage } = useLinkImageInView(
    size.web,
    typeSize,
    photo_path,
    imgFormat,
    imageProxyHost,
    file.contentType,
    true
  )

  return (
    <div className={styles["root"]}>
      <SmoothTransition visible={!isImageLoaded}>
        <Spinner className={styles["spinner"]}></Spinner>
      </SmoothTransition>
      {isReadyImage && (
        <GoogleImage
          {...longTapEvents}
          src={srcImage}
          className={cx(styles["image"], isImageLoaded && styles["ready"])}
          alt=""
          onLoad={onImageLoad}
        />
      )}
    </div>
  )
}

export { PhotoSlide }

import React from "react"
import cx from "clsx"
import { useI18n } from "next-localization"
import { useTour } from "@reactour/tour"

import { useKeyPress } from "@app/utils"
import { usePhotoCarousel, usePhotoLike } from "@app/features/photo-carousel/store/hooks"
import { useDownloadPhoto } from "@app/features/download/hooks"
import { getPhotoDirectURL } from "@app/features/photo/utils"
import { useFavoritesList } from "@app/features/favorites/hooks"
import { useGetFavoritePhotos, useSetFavoritePhotos } from "@app/features/masonry/hooks"
import { useSharing } from "@app/features/sharing/hooks"
import { useGallery, useGlobalState } from "@app/contexts"
import { useAuthorization } from "@app/features/user/stores"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"

import { fetchFavoriteLists } from "features/favorites/requests"
import { checkFavoriteLimit } from "features/favorites/utils"

import { Tooltip } from "@app/ui/tooltip"
import { Download, Share, Cross, Like, Printer } from "@app/ui/icons"

import { useLinkImage } from "@app/utils/hooks"

import { checkOnboardingActivation } from "@app/features/onboarding/utils"
import { HidingMediaFile } from "@app/features/hiding-media-files/components"
import { isFirstVisit } from "@app/features/onboarding/utils"

import { IS_FIRST_VISIT_LOCAL_STORAGE_KEY, STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"

import { IconWrapper } from "@app/features/theme/icon-wrapper"

import { COLORS } from "@app/constants"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"

import { OnboardingHint } from "@app/features/onboarding/components/onboarding-hint"
import { OnboardingPrintBlock } from "@app/features/onboarding/components/onboarding-print-block"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { PRINT_ONBOARDING_PRINT_HINT } from "@app/features/onboarding/constants"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./header.module.scss"

function Header({ type, favoritesListId, isDownloadEnabled, isCanBeHidden, isActionsEnabled }) {
  const { isUserAuthorized } = useAuthorization()
  const { $hidePhotoCarousel, $activePhoto } = usePhotoCarousel()
  const downloadPhoto = useDownloadPhoto($activePhoto?.id, $activePhoto?.photos)
  const $showProductsModal = useShowProductsModal()

  const { gallery } = useGallery()
  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  const {
    isFavoritesSectionActivated,
    userAgent: { isMobile },
    imageProxyHost,
    size,
    imgFormat,
  } = useGlobalState()

  const photo_path = $activePhoto?.photos && $activePhoto?.photos[0]
  const isOnboardingEnabled = checkOnboardingActivation(gallery.settings)
  const isFirstVisitGallery = isFirstVisit(IS_FIRST_VISIT_LOCAL_STORAGE_KEY)

  const [linkImage] = useLinkImage(size.md, photo_path, $activePhoto?.photos)

  const getSrcImage = function (size, linkImage, imgFormat, sizes) {
    const imageSizeFormat = `=w${size}${imgFormat}`

    return linkImage.includes(imageProxyHost) ? linkImage : `${linkImage}${imageSizeFormat}`
  }
  const srcImage = getSrcImage(size.xs, linkImage, imgFormat, $activePhoto?.photos?.xs)
  const imageURL = srcImage

  const like = useFavoritesList({ photoId: $activePhoto?.id, imageSrc: imageURL, favoritesListId })
  const setPhotoLikeStatus = usePhotoLike()
  const isFavoritePage = type === "favorites"

  const [isLikedCS, setIsLikedCS] = React.useState($activePhoto?.photos && $activePhoto.isLiked)

  React.useEffect(() => {
    if ($activePhoto?.photos) setIsLikedCS($activePhoto.isLiked)
    if (isFavoritePage && $activePhoto?.photos) {
      setIsLikedCS(true)
    }
  }, [$activePhoto])

  const $favoritePhotos = useGetFavoritePhotos()
  const $setFavoritePhotos = useSetFavoritePhotos()

  async function handleLikeClick() {
    if (!isFavoritePage) {
      like(isLikedCS)
      if (isUserAuthorized) {
        setPhotoLikeStatus($activePhoto.id, !isLikedCS)
        setIsLikedCS(!isLikedCS)

        const favoriteLists = await fetchFavoriteLists({ galleryId: gallery.id })
        const isFull = checkFavoriteLimit(gallery?.id, favoriteLists)
        // Если лимит достигнут, и лайк по фото, то отменяем лайк
        // (именно отмена, чтобы не было задержки при клике на лайк, тк проверка async await)
        if (isFull && !isLikedCS) {
          setIsLikedCS(false)
          setPhotoLikeStatus($activePhoto.id, false)
        }
      }

      return
    }

    if (isFavoritePage) {
      const filteredPhotos = $favoritePhotos.filter((photo) => photo.id !== $activePhoto.id)

      $setFavoritePhotos(filteredPhotos)
      like(isLikedCS)
      $hidePhotoCarousel()

      return
    }
  }

  const i18n = useI18n()

  const share = useSharing()
  function handleShareClick() {
    trackYandexMetrikaAction(
      isMobile ? "click-share-photo-full-screen-mobile" : "click-share-photo-full-screen-desktop"
    )
    share("photo", {
      title: $activePhoto.name,
      url: getPhotoDirectURL($activePhoto.id),
      fileURL: imageURL,
    })
  }

  function handleDownloadClick() {
    if (isMobile) {
      trackYandexMetrikaAction("click-download-photo-full-screen-mobile")
    }
    if (!isMobile) {
      trackYandexMetrikaAction("click-download-photo-full-screen-desktop")
    }
    downloadPhoto()
  }

  useKeyPress("Escape", $hidePhotoCarousel)

  const { setIsOpen } = useTour()

  const { $isOnboardingPrintVisible, $hideOnboardingPrint, $showOnboardingTour } = useOnboarding()

  React.useEffect(() => {
    if (isMobile && isOnboardingEnabled && isFirstVisitGallery) {
      $showOnboardingTour()
      $hideOnboardingPrint()
      setTimeout(() => {
        setIsOpen(true)
      }, 300)
    }
  }, [$activePhoto])

  const cover = gallery?.cover?.sm
  const printOnboardingText = "Вы можете заказать книгу \nс вашими фото."

  return (
    <header className={styles["root"]}>
      {isActionsEnabled && (
        <>
          {isPrintableProductsEnabled && (
            <div className={styles["action"]}>
              <Tooltip text={i18n.t("tooltip.printPhoto")}>
                <OnboardingHint
                  canVisible={$isOnboardingPrintVisible}
                  visibleOnView={true}
                  inViewMargin={0}
                  isMobileFixed={true}
                  isTransparentClickEnabled={true}
                  onTransparentClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                  id={PRINT_ONBOARDING_PRINT_HINT}
                  arrowPosition={{ desktop: "right-none", mobile: "none" }}
                  offset="20px"
                  space="0 -10px 0 0"
                  type="circle"
                  withImageType={true}
                  isClickableContent={true}
                  content={
                    <OnboardingPrintBlock description={printOnboardingText} cover={cover}></OnboardingPrintBlock>
                  }
                >
                  <button
                    className={cx(styles["button"], QA_CLASS_NAMES.fullSize.print)}
                    onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                  >
                    <IconWrapper
                      light={<Printer className={styles["mark-icon"]} />}
                      dark={<Printer stroke={COLORS.white} />}
                    />
                  </button>
                </OnboardingHint>
              </Tooltip>
            </div>
          )}
          {isCanBeHidden && (
            <div className={styles["hiding-action"]}>
              <HidingMediaFile
                mediaFileById={$activePhoto}
                buttonClassName={cx(styles["button"], QA_CLASS_NAMES.fullSize.hiding)}
                classNameIcon={styles["hidden-icon"]}
                tooltipPosition="down-left"
              ></HidingMediaFile>
            </div>
          )}
          {isDownloadEnabled && (
            <div className={styles["action"]}>
              <Tooltip text={i18n.t("tooltip.downloadPhoto")}>
                <button
                  className={cx(
                    styles["button"],
                    STEPS_CLASS_NAME_TOUR.download.name,
                    QA_CLASS_NAMES.fullSize.download
                  )}
                  onClick={handleDownloadClick}
                >
                  <IconWrapper
                    light={<Download stroke="#000" width="18" height="18" />}
                    dark={<Download stroke="#fff" width="18" height="18" />}
                  />
                </button>
              </Tooltip>
            </div>
          )}
          <div className={styles["action"]}>
            <Tooltip text={i18n.t("common.share")}>
              <button
                className={cx(
                  styles["button"],
                  styles["share-button"],
                  STEPS_CLASS_NAME_TOUR.share.name,
                  QA_CLASS_NAMES.fullSize.share
                )}
                onClick={handleShareClick}
              >
                <IconWrapper
                  light={<Share width="21" height="17" fill="#000" />}
                  dark={<Share width="21" height="17" fill="#fff" />}
                />
              </button>
            </Tooltip>
          </div>
          {isFavoritesSectionActivated && (
            <div className={styles["action"]}>
              <Tooltip text={isLikedCS ? i18n.t("tooltip.removeFromFavorites") : i18n.t("tooltip.addToFavorites")}>
                <button
                  className={cx(
                    styles["button"],
                    styles["like-button"],
                    STEPS_CLASS_NAME_TOUR.like.name,
                    { [QA_CLASS_NAMES.fullSize.favourites]: !isLikedCS },
                    { [QA_CLASS_NAMES.fullSize.outFavourites]: isLikedCS }
                  )}
                  onClick={handleLikeClick}
                >
                  <IconWrapper
                    light={
                      <Like
                        width="18"
                        height="16"
                        className={cx({
                          [styles["liked"]]: isLikedCS,
                        })}
                        stroke="#000"
                        fill="none"
                      />
                    }
                    dark={
                      <Like
                        width="18"
                        height="16"
                        className={cx({
                          [styles["liked"]]: isLikedCS,
                        })}
                        stroke="#fff"
                        fill="none"
                      />
                    }
                  />
                </button>
              </Tooltip>
            </div>
          )}
        </>
      )}
      <button
        className={cx(styles["action"], styles["cross"], QA_CLASS_NAMES.fullSize.close)}
        onClick={$hidePhotoCarousel}
      >
        <IconWrapper
          light={<Cross width={16} height={16} stroke="#000" />}
          dark={<Cross width={16} height={16} stroke="#fff" />}
        />
      </button>
    </header>
  )
}

export { Header }

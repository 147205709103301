import React from "react"
import cx from "clsx"
import { useI18n } from "next-localization"

import { useKeyPress } from "@app/utils"
import { usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { useDownloadVideo } from "@app/features/download/hooks"
import { getVideoDirectUrl } from "@app/features/video/utils"
import { useSharing } from "@app/features/sharing/hooks"
import { useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"

import { HidingMediaFile } from "@app/features/hiding-media-files/components"

import { Tooltip } from "@app/ui/tooltip"
import { Download, Share, Cross } from "@app/ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import { useLinkVideo } from "@app/utils/hooks"

import styles from "./header-video.module.scss"

function HeaderVideo({ isDownloadEnabled, isCanBeHidden, isActionsEnabled }) {
  const { $hidePhotoCarousel, $activePhoto } = usePhotoCarousel()
  const downloadVideo = useDownloadVideo($activePhoto?.id, [$activePhoto?.fileKey])

  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const [linkVideo] = useLinkVideo($activePhoto?.fileKey)

  const i18n = useI18n()

  const share = useSharing()
  function handleShareClick() {
    trackYandexMetrikaAction(
      isMobile ? "click-share-video-full-screen-mobile" : "click-share-video-full-screen-desktop"
    )
    share("video", {
      title: $activePhoto.name,
      url: getVideoDirectUrl($activePhoto.id),
      fileURL: linkVideo,
    })
  }

  function handleDownloadClick() {
    if (isMobile) {
      trackYandexMetrikaAction("click-download-video-full-screen-mobile")
    }
    if (!isMobile) {
      trackYandexMetrikaAction("click-download-video-full-screen-desktop")
    }
    downloadVideo()
  }

  useKeyPress("Escape", $hidePhotoCarousel)

  return (
    <header className={styles["root"]}>
      {isActionsEnabled && (
        <>
          {isCanBeHidden && (
            <div className={styles["hiding-action"]}>
              <HidingMediaFile
                type="video"
                mediaFileById={$activePhoto}
                buttonClassName={styles["button"]}
                classNameIcon={styles["hidden-icon"]}
                tooltipPosition="down-left"
              ></HidingMediaFile>
            </div>
          )}
          {isDownloadEnabled && (
            <div className={styles["action"]}>
              <Tooltip text={i18n.t("tooltip.downloadVideo")}>
                <button className={styles["button"]} onClick={handleDownloadClick}>
                  <IconWrapper
                    light={<Download stroke="#000" width="18" height="18" />}
                    dark={<Download stroke="#fff" width="18" height="18" />}
                  />
                </button>
              </Tooltip>
            </div>
          )}
          <div className={styles["action"]}>
            <Tooltip text={i18n.t("common.share")}>
              <button className={cx(styles["button"], styles["share-button"])} onClick={handleShareClick}>
                <IconWrapper
                  light={<Share width="21" height="17" fill="#000" />}
                  dark={<Share width="21" height="17" fill="#fff" />}
                />
              </button>
            </Tooltip>
          </div>
        </>
      )}
      <button className={cx(styles["action"], styles["cross"])} onClick={$hidePhotoCarousel}>
        <IconWrapper
          light={<Cross width={16} height={16} stroke="#000" />}
          dark={<Cross width={16} height={16} stroke="#fff" />}
        />
      </button>
    </header>
  )
}

export { HeaderVideo }

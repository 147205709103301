import * as React from "react"

import { useInView } from "react-intersection-observer"
import { useLinkVideoInView } from "@app/utils/hooks"
import { useDisableContextMenu } from "utils"

import { VideoPlayer } from "@app/features/video/components/video-player"
import { Spinner } from "@app/ui/spinner"
import { SmoothTransition } from "@app/ui/animations"

import styles from "./video-slide.module.scss"

interface VideoSlidePropsInterface {
  file: {
    [key: string]: any
  }
}

const VideoSlide = ({ file }: VideoSlidePropsInterface) => {
  const videoType =
    file?.contentType === "video/webm"
      ? 'video/webm; codecs="vp8, vorbis"'
      : 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
  const ref = React.useRef(null)
  const refRoot = React.useRef(null)

  const { srcVideo, isReadyVideo } = useLinkVideoInView(file?.fileKey, file?.contentType, true)
  // const src = `${srcVideo}#t=0.001`

  const [inViewRef, inView] = useInView()
  const refInView = React.useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useDisableContextMenu(refRoot, true)

  return (
    <div className={styles["root"]} ref={refRoot}>
      <div className={styles["in-view"]} ref={refInView}></div>
      <SmoothTransition visible={!inView && !isReadyVideo}>
        <Spinner className={styles["spinner"]}></Spinner>
      </SmoothTransition>
      {inView && isReadyVideo && <VideoPlayer srcVideo={srcVideo} />}
    </div>
  )
}

export { VideoSlide }

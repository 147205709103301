import * as React from "react"
import { Player, ControlBar, BigPlayButton } from "video-react"

import "video-react/dist/video-react.css"

import styles from "./video-player.module.scss"

interface VideoSlidePropsInterface {
  srcVideo: string
  autoPlay?: boolean
  muted?: boolean
  autoHideTime?: number
}

const VideoPlayer = ({ srcVideo, autoPlay = false, muted = false, autoHideTime = 2000 }: VideoSlidePropsInterface) => {
  const [videoPlayer, setVideoPlayer] = React.useState(null)
  const [playerState, setPlayerState] = React.useState(null)
  const [playerOperationState, setPlayerOperationState] = React.useState(null)

  const handleStateChange = (state, prevState) => {
    setPlayerState({
      player: state,
      currentTime: state.currentTime,
    })
  }

  const handleOperationChange = (state) => {
    setPlayerOperationState({
      player: state,
      action: state.operation.action,
    })
  }

  React.useEffect(() => {
    if (videoPlayer) {
      videoPlayer.subscribeToStateChange(handleStateChange)
      videoPlayer.manager.subscribeToOperationStateChange(handleOperationChange)
    }
  }, [videoPlayer])

  React.useEffect(() => {
    if (playerOperationState?.action === "pause") {
      // возвращаем фокус на плеер, чтобы работал swiper на мобильном (ранее проваливался клик при click pause)
      videoPlayer.manager.rootElement.focus()
    }
  }, [playerOperationState])

  return (
    <Player
      ref={(player) => {
        setVideoPlayer(player)
      }}
      className={styles["player"]}
      fluid={false}
      width="100%"
      height="100%"
      autoPlay={autoPlay}
      muted={muted}
    >
      <source src={srcVideo} />
      <BigPlayButton position="center" />
      <ControlBar autoHideTime={autoHideTime} className={styles["video-controls"]} />
    </Player>
  )
}

export { VideoPlayer }

import React from "react"

import { OnboardingTour } from "@app/features/onboarding/components/onboarding-tour"

import { useGlobalState } from "@app/contexts"
import { usePhotoCarousel } from "features/photo-carousel/store/hooks"
import { Header, HeaderVideo, CarouselEngine } from "features/photo-carousel/components"

import { CenterElipsis } from "@app/ui/center-elipsis"

import { IS_FIRST_VISIT_LOCAL_STORAGE_KEY, STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"

import styles from "./photo-carousel.module.scss"

function PhotoCarousel({ type, favoritesListId, isDownloadEnabled, isCanBeHidden }) {
  const { isFavoritesSectionActivated } = useGlobalState()
  const { $isPhotoCarouselVisible, $activePhoto } = usePhotoCarousel()
  const mediaFileType = $activePhoto?.type

  const stepsClassNameTour = []
  if (isDownloadEnabled) {
    stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.download.name)
  }

  stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.share.name)

  if (isFavoritesSectionActivated) {
    stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.like.name)
  }

  if ($isPhotoCarouselVisible && $activePhoto) {
    return (
      <OnboardingTour stepsClassName={stepsClassNameTour} localStorageKey={IS_FIRST_VISIT_LOCAL_STORAGE_KEY}>
        <aside className={styles["root"]}>
          {mediaFileType === "photo" && (
            <Header
              type={type}
              favoritesListId={favoritesListId}
              isDownloadEnabled={isDownloadEnabled}
              isActionsEnabled={$activePhoto?.photos}
              isCanBeHidden={isCanBeHidden}
            ></Header>
          )}
          {mediaFileType === "video" && (
            <HeaderVideo
              isDownloadEnabled={isDownloadEnabled}
              isCanBeHidden={isCanBeHidden}
              isActionsEnabled={$activePhoto?.fileKey}
            ></HeaderVideo>
          )}
          <CarouselEngine isDownloadEnabled={isDownloadEnabled}></CarouselEngine>
          <p className={styles["name"]}>{$activePhoto.name}</p>
          <p className={styles["name-mobile"]}>
            <CenterElipsis beforeCharacters={12} afterCharacters={12} text={$activePhoto.name} />
          </p>
        </aside>
      </OnboardingTour>
    )
  }

  return null
}

export { PhotoCarousel }

import { FunctionComponent } from "react"

type TCenterElipsisProps = {
  beforeCharacters: number
  afterCharacters: number
  text: string
}

const CenterElipsis: FunctionComponent<TCenterElipsisProps> = ({
  beforeCharacters,
  afterCharacters,
  text,
}: TCenterElipsisProps): JSX.Element => {
  if (beforeCharacters + afterCharacters + 3 > `${text}`.length) {
    return <>{text}</>
  }

  const textlength = text.length
  const beforeText = text.substring(0, beforeCharacters - 1).trim()
  const afterText = text.substring(textlength - afterCharacters, textlength).trim()

  return (
    <>
      {beforeText}…{afterText}
    </>
  )
}

export { CenterElipsis }

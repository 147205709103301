import { useGlobalState } from "@app/contexts"

import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { getProductDiscountData } from "@app/features/product-discount/utils"

import styles from "./onboarding-print-block.module.scss"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { useI18n } from "next-localization"

function OnboardingPrintBlock({ description, cover }) {
  const $showProductsModal = useShowProductsModal()
  const discount = getProductDiscountData("PHOTOBOOK")
  const discountPercent = discount?.percent
  const { imgFormat } = useGlobalState()
  const { t } = useI18n()

  return (
    <div className={styles["root"]} onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}>
      <div className={styles["image-block"]}>
        <div className={styles["labels"]}>
          {!discount && <div className={styles["label"]}>{t("product.photobook.label").toUpperCase()}</div>}
          {discount && <div className={styles["discount-label"]}>-{discountPercent}%</div>}
        </div>
        {cover && (
          <>
            <img className={styles["fon"]} src={`${cover}${imgFormat}`} alt="" />
            <img className={styles["image"]} src="/img/product/banner/vigbo-print-header__fon.png" alt="Print" />
          </>
        )}
        {!cover && <img className={styles["image"]} src="/img/product/banner/vigbo-print-header.jpg" alt="Print" />}
      </div>
      {description && <p className={styles["description"]}>{description}</p>}
    </div>
  )
}

export { OnboardingPrintBlock }

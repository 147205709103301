import React, { ReactElement } from "react"
import cn from "clsx"
import styles from "./onboarding-hint-content.module.scss"

type TOnboardingHintContentProps = {
  children: string | string[] | ReactElement | ReactElement[] | JSX.Element | JSX.Element[]
  referenceWidth: number
  arrowPosition: {
    desktop?: "left" | "center" | "bottom-center" | "right" | "left-end" | "right-none"
    mobile?: "left" | "center" | "bottom-center" | "right" | "left-end" | "none"
  }
  withImageType?: boolean
  position: "top" | "bottom"
  onClick?: (event) => void
}

const OnboardingHintContent = React.forwardRef<HTMLDivElement, TOnboardingHintContentProps>(
  (
    {
      children,
      referenceWidth,
      arrowPosition = {
        desktop: "center",
        mobile: "center",
      },
      position = "top",
      withImageType = false,
      onClick,
    },
    ref
  ) => {
    return (
      <div className={styles["wrapper"]} ref={ref} onClick={(event) => onClick(event)}>
        <div style={{ width: referenceWidth + "px", height: "1px" }}></div>
        <div
          className={cn(
            styles["root"],
            styles[`slick-${position}`],
            styles[`desktop-${arrowPosition.desktop}`],
            styles[`mobile-${arrowPosition.mobile}`],
            { [styles["width-image"]]: withImageType }
          )}
        >
          {children}
        </div>
      </div>
    )
  }
)

OnboardingHintContent.displayName = "OnboardingHintContent"

export { OnboardingHintContent }

import { FunctionComponent, ReactElement } from "react"

import { ProductGroupsList } from "@app/features/products/components/product-groups-list"

import styles from "./products-section.module.scss"

type TProductsSectionProps = {
  title: string
  description: string
  onlyAvailable?: boolean
  position?: "modal" | "footer"
}

const ProductsSection: FunctionComponent<TProductsSectionProps> = ({
  title,
  description,
  onlyAvailable = false,
  position,
}: TProductsSectionProps): ReactElement => {
  return (
    <div className={styles["root"]}>
      <h2 className={styles["title"]}>{title}</h2>
      <p className={styles["description"]}>{description}</p>
      <div className={styles["content"]}>
        <ProductGroupsList onlyAvailable={onlyAvailable} position={position} />
      </div>
    </div>
  )
}

export { ProductsSection }

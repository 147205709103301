import { isClient } from "@app/utils"

function getAnimationSpeed(isMobile: boolean, isTablet: boolean): number {
  let speed: number = 0

  const isTouchDevice: boolean = isClient && (isMobile || isTablet)

  if (isTouchDevice) {
    speed = 120
  }

  return speed
}

export { getAnimationSpeed }
